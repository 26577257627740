.App {
  text-align: center;
}

.dashboard-card {
  text-align: center;
  /*margin-bottom: 8px;*/
}

.login-content {
  padding-top: 1rem;
  margin: 7% auto 16px auto;
  text-align: center;
}

.login-pw-error {
  animation: shake 0.2s ease-in-out 0s 2;
}

@keyframes shake {
  0% { margin-left: 0rem; }
  25% { margin-left: 0.5rem; }
  75% { margin-left: -0.5rem; }
  100% { margin-left: 0rem; }
}

.loginbutton {
  margin-top: 24px;
}

.loginerror {
  color: #f44336;
}

.appbar-text {
  padding-left: 4px;
}

.dashboard-content {
  padding: 0px 10px;
}

.loadingbar-box {
  position: fixed;
  height: 4px;
  width: 100%;
  top: 0;
  z-index: 1000;
}

.loadingbar {
  box-shadow: 0 2px 1px -1px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12);
}

.stempeln button {
  width: 100%;
}

hr {
  margin: 16px 0 !important;
}

.headline {
  margin: 12px 0 !important;
}
